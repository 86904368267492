<template>
  <Notify v-if="$showNotify?.value" show @close="$setShowNotify(false)">
    <template #icon>
      <NotifyIcon class="h-6 w-6 fill-green-500" aria-hidden="true" />
    </template>
    <template #title>
      {{ $notify.value.title }}
    </template>
    <template #text>
      {{ $notify.value.text }}
    </template>
    <template v-if="$notify.value?.redirects?.length" #footer>
      <span v-for="redirect in $notify.value?.redirects" :key="redirect.label">
        <NuxtLink
          v-if="redirect.path && redirect.label"
          :to="redirect.path"
          rule="button"
          class="rounded-md bg-white text-sm font-medium text-primary-600 hover:text-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          @click="$showNotify.value = false"
        >
          {{ redirect.label }}
        </NuxtLink>
      </span>
    </template>
  </Notify>

  <main v-if="isLoadingAuth" class="relative isolate min-h-full text-gray-800">
    <div class="mx-auto max-w-7xl px-6 -py-32 text-center sm:py-40 lg:px-8">
      <!-- <img class="h-5 m-auto md:h-20" :src="LogoBlack" alt="logo"> -->
      <!-- <h1 class="text-3xl text-gray-700 -font-bold tracking-tight -text-white sm:text-4xl">
        CI
      </h1> -->

      <img class="h-5 m-auto md:h-20" :src="LogoBlack" alt="logo">
      <p class="mt-10 text-center">
        Aguarde um instante ...
      </p>
    </div>
  </main>

  <div v-else>
    <NuxtPage />
  </div>
</template>

<script lang="ts" setup>
import LogoBlack from "~/assets/images/logo/LOGO_800X200.png";

const route = useRoute();
const router = useRouter();
const context = useNuxtApp();
const isLoadingAuth = ref(true);

onMounted(() => {
  context.$fetchLogin()
    .then(() => {
      if (context.$user.value) {
        if (route.path === "/") {
          router.replace("/home");
        }

        context.$startNotifyListening();

        // new Realtime().set<NotifyI>("/notify/" + context.$user.value.uid, {
        //   title: "Atualização",
        //   text: "André atualizou o usuário Guilherme",
        //   userId: context.$user.value.uid
        // });
      } else {
        router.replace("/");
      }
    })
    .finally(() => isLoadingAuth.value = false);
});

</script>
