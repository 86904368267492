import { default as _91id_93YB1gij7LZIMeta } from "/opt/buildhome/repo/pages/home/ci/[id].vue?macro=true";
import { default as createFT4l50zZArMeta } from "/opt/buildhome/repo/pages/home/ci/create.vue?macro=true";
import { default as indexX8FFvhvP8SMeta } from "/opt/buildhome/repo/pages/home/dashboard/index.vue?macro=true";
import { default as _91id_93NHtEOt9hdCMeta } from "/opt/buildhome/repo/pages/home/settings/department/[id].vue?macro=true";
import { default as createlhOaGIYnd2Meta } from "/opt/buildhome/repo/pages/home/settings/department/create.vue?macro=true";
import { default as index89Vyxy0VdtMeta } from "/opt/buildhome/repo/pages/home/settings/department/index.vue?macro=true";
import { default as _91id_93c9iMk3lqu0Meta } from "/opt/buildhome/repo/pages/home/settings/secretary/[id].vue?macro=true";
import { default as createsVgEVZjpPFMeta } from "/opt/buildhome/repo/pages/home/settings/secretary/create.vue?macro=true";
import { default as index2KT1jlRnzmMeta } from "/opt/buildhome/repo/pages/home/settings/secretary/index.vue?macro=true";
import { default as _91id_93IjacAePCVdMeta } from "/opt/buildhome/repo/pages/home/settings/user/[id].vue?macro=true";
import { default as createVtDDr2pgipMeta } from "/opt/buildhome/repo/pages/home/settings/user/create.vue?macro=true";
import { default as editipwb6Nup0YMeta } from "/opt/buildhome/repo/pages/home/settings/user/edit.vue?macro=true";
import { default as indexmeEog3eJR8Meta } from "/opt/buildhome/repo/pages/home/settings/user/index.vue?macro=true";
import { default as home4pMUrBFKiHMeta } from "/opt/buildhome/repo/pages/home.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as indexVOrFrgjAGIMeta } from "/opt/buildhome/repo/pages/profile/index.vue?macro=true";
import { default as _91token_93ULhUjFcQ6gMeta } from "/opt/buildhome/repo/pages/recovery/[token].vue?macro=true";
import { default as index1Liy9c6jhgMeta } from "/opt/buildhome/repo/pages/recovery/index.vue?macro=true";
export default [
  {
    name: home4pMUrBFKiHMeta?.name ?? "home",
    path: home4pMUrBFKiHMeta?.path ?? "/home",
    meta: home4pMUrBFKiHMeta || {},
    alias: home4pMUrBFKiHMeta?.alias || [],
    redirect: home4pMUrBFKiHMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93YB1gij7LZIMeta?.name ?? "home-ci-id",
    path: _91id_93YB1gij7LZIMeta?.path ?? "ci/:id()",
    meta: _91id_93YB1gij7LZIMeta || {},
    alias: _91id_93YB1gij7LZIMeta?.alias || [],
    redirect: _91id_93YB1gij7LZIMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/ci/[id].vue").then(m => m.default || m)
  },
  {
    name: createFT4l50zZArMeta?.name ?? "home-ci-create",
    path: createFT4l50zZArMeta?.path ?? "ci/create",
    meta: createFT4l50zZArMeta || {},
    alias: createFT4l50zZArMeta?.alias || [],
    redirect: createFT4l50zZArMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/ci/create.vue").then(m => m.default || m)
  },
  {
    name: indexX8FFvhvP8SMeta?.name ?? "home-dashboard",
    path: indexX8FFvhvP8SMeta?.path ?? "dashboard",
    meta: indexX8FFvhvP8SMeta || {},
    alias: indexX8FFvhvP8SMeta?.alias || [],
    redirect: indexX8FFvhvP8SMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93NHtEOt9hdCMeta?.name ?? "home-settings-department-id",
    path: _91id_93NHtEOt9hdCMeta?.path ?? "settings/department/:id()",
    meta: _91id_93NHtEOt9hdCMeta || {},
    alias: _91id_93NHtEOt9hdCMeta?.alias || [],
    redirect: _91id_93NHtEOt9hdCMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/settings/department/[id].vue").then(m => m.default || m)
  },
  {
    name: createlhOaGIYnd2Meta?.name ?? "home-settings-department-create",
    path: createlhOaGIYnd2Meta?.path ?? "settings/department/create",
    meta: createlhOaGIYnd2Meta || {},
    alias: createlhOaGIYnd2Meta?.alias || [],
    redirect: createlhOaGIYnd2Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/settings/department/create.vue").then(m => m.default || m)
  },
  {
    name: index89Vyxy0VdtMeta?.name ?? "home-settings-department",
    path: index89Vyxy0VdtMeta?.path ?? "settings/department",
    meta: index89Vyxy0VdtMeta || {},
    alias: index89Vyxy0VdtMeta?.alias || [],
    redirect: index89Vyxy0VdtMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/settings/department/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93c9iMk3lqu0Meta?.name ?? "home-settings-secretary-id",
    path: _91id_93c9iMk3lqu0Meta?.path ?? "settings/secretary/:id()",
    meta: _91id_93c9iMk3lqu0Meta || {},
    alias: _91id_93c9iMk3lqu0Meta?.alias || [],
    redirect: _91id_93c9iMk3lqu0Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/settings/secretary/[id].vue").then(m => m.default || m)
  },
  {
    name: createsVgEVZjpPFMeta?.name ?? "home-settings-secretary-create",
    path: createsVgEVZjpPFMeta?.path ?? "settings/secretary/create",
    meta: createsVgEVZjpPFMeta || {},
    alias: createsVgEVZjpPFMeta?.alias || [],
    redirect: createsVgEVZjpPFMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/settings/secretary/create.vue").then(m => m.default || m)
  },
  {
    name: index2KT1jlRnzmMeta?.name ?? "home-settings-secretary",
    path: index2KT1jlRnzmMeta?.path ?? "settings/secretary",
    meta: index2KT1jlRnzmMeta || {},
    alias: index2KT1jlRnzmMeta?.alias || [],
    redirect: index2KT1jlRnzmMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/settings/secretary/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93IjacAePCVdMeta?.name ?? "home-settings-user-id",
    path: _91id_93IjacAePCVdMeta?.path ?? "settings/user/:id()",
    meta: _91id_93IjacAePCVdMeta || {},
    alias: _91id_93IjacAePCVdMeta?.alias || [],
    redirect: _91id_93IjacAePCVdMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/settings/user/[id].vue").then(m => m.default || m)
  },
  {
    name: createVtDDr2pgipMeta?.name ?? "home-settings-user-create",
    path: createVtDDr2pgipMeta?.path ?? "settings/user/create",
    meta: createVtDDr2pgipMeta || {},
    alias: createVtDDr2pgipMeta?.alias || [],
    redirect: createVtDDr2pgipMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/settings/user/create.vue").then(m => m.default || m)
  },
  {
    name: editipwb6Nup0YMeta?.name ?? "home-settings-user-edit",
    path: editipwb6Nup0YMeta?.path ?? "settings/user/edit",
    meta: editipwb6Nup0YMeta || {},
    alias: editipwb6Nup0YMeta?.alias || [],
    redirect: editipwb6Nup0YMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/settings/user/edit.vue").then(m => m.default || m)
  },
  {
    name: indexmeEog3eJR8Meta?.name ?? "home-settings-user",
    path: indexmeEog3eJR8Meta?.path ?? "settings/user",
    meta: indexmeEog3eJR8Meta || {},
    alias: indexmeEog3eJR8Meta?.alias || [],
    redirect: indexmeEog3eJR8Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/home/settings/user/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexVOrFrgjAGIMeta?.name ?? "profile",
    path: indexVOrFrgjAGIMeta?.path ?? "/profile",
    meta: indexVOrFrgjAGIMeta || {},
    alias: indexVOrFrgjAGIMeta?.alias || [],
    redirect: indexVOrFrgjAGIMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_93ULhUjFcQ6gMeta?.name ?? "recovery-token",
    path: _91token_93ULhUjFcQ6gMeta?.path ?? "/recovery/:token()",
    meta: _91token_93ULhUjFcQ6gMeta || {},
    alias: _91token_93ULhUjFcQ6gMeta?.alias || [],
    redirect: _91token_93ULhUjFcQ6gMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/recovery/[token].vue").then(m => m.default || m)
  },
  {
    name: index1Liy9c6jhgMeta?.name ?? "recovery",
    path: index1Liy9c6jhgMeta?.path ?? "/recovery",
    meta: index1Liy9c6jhgMeta || {},
    alias: index1Liy9c6jhgMeta?.alias || [],
    redirect: index1Liy9c6jhgMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/recovery/index.vue").then(m => m.default || m)
  }
]